import {ReservationSummary} from '@api/model/reservationSummary';
import {ReservationFriendlyState} from '@views/reservation-details/state/reservation-friendly-state';
import {toReservationFriendlyState} from '@views/reservation-details/state/reservation-friendly-state.converter';
import * as moment from 'moment';

export function sortActiveOrUpcomingReservations(reservations: ReservationSummary[]): ReservationSummary[] {
  return reservations
    .filter(reservation => isActiveOrUpcomingReservation(reservation))
    .sort((firstReservation, secondReservation) => {
      return moment(firstReservation.check_out_date).diff(moment(secondReservation.check_out_date));
    });
}

export function isActiveOrUpcomingReservation(reservation: ReservationSummary): boolean {
  return [ReservationFriendlyState.ACTIVE, ReservationFriendlyState.UPCOMING].includes(toReservationFriendlyState(reservation));
}
