/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ZzzingServicePricingType = 'FREE' | 'SIMPLE' | 'ADVANCED';

export const ZzzingServicePricingType = {
    Free: 'FREE' as ZzzingServicePricingType,
    Simple: 'SIMPLE' as ZzzingServicePricingType,
    Advanced: 'ADVANCED' as ZzzingServicePricingType
};

