import {Pipe, PipeTransform} from '@angular/core';

/**
 * A pipe to be used to convert "PascalCase" into "Title Case"
 */
@Pipe({
  name: 'pascalToTitle'
})
export class PascalToTitlePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string): string {
    const splitWithSpaces = value.slice(1).replace(/([A-Z])/g, ' $1');
    return value.charAt(0).toUpperCase() + splitWithSpaces;
  }

}
