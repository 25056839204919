import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ReservationSummary} from '@api/model/reservationSummary';
import {ZzzingGuestUser} from '@api/model/zzzingGuestUser';
import {environment} from '@env/environment';
import {ReservationType} from '@gen/api';
import {Kommunicate} from '@ionic-native/kommunicate/ngx';
import {ReservationsHelperService} from '@shared/reservations/reservations-helper.service';
import {Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {catchError, map, switchMap} from 'rxjs/operators';

export type UsersApiResponse = {
  response?: UserDetailsApiResponse[];
};

export type UserDetailsApiResponse = {
  unreadCount?: number,
  userId?: string
};

@Injectable({
  providedIn: 'root'
})
export class GuestChatService {

  constructor(private kommunicate: Kommunicate,
              private reservationsHelperService: ReservationsHelperService,
              private httpClient: HttpClient) {
  }

  public login(guest: ZzzingGuestUser): Observable<any> {
    return this.getKommunicateUser(guest).pipe(switchMap(user => {
      return fromPromise(this.kommunicate.login(user).catch(e => console.error(`Kommunicate: Login failed for user: ${guest.id}`, e)));
    }));
  }

  public logout(): Observable<any> {
    const logoutPromise = this.kommunicate.logout().catch(e => console.error('Kommunicate: Logout failed', e));
    return fromPromise(logoutPromise);
  }

  public openChat(guest: ZzzingGuestUser): Observable<any> {
    return this.logout().pipe(
      switchMap(() => this.login(guest)),
      switchMap(() => fromPromise(this.kommunicate.conversationBuilder({launchAndCreateIfEmpty: true, skipConversationList: true}))));
  }

  public getUnreadMessagesCount(guest: ZzzingGuestUser): Observable<number> {
    return this.getUserDetails(guest).pipe(map(result => result?.response?.[0]?.unreadCount));
  }

  public getUserDetails(guest: ZzzingGuestUser): Observable<UsersApiResponse> {
    return this.httpClient.post<UsersApiResponse>(
      `${environment.kommunicate.apiUrl}/rest/ws/user/v2/detail`,
      {userIdList: [guest.id]},
      {headers: {'Api-Key': environment.kommunicate.apiKey}}).pipe(catchError(() => of({})));
  }

  private getKommunicateUser(guest: ZzzingGuestUser): Observable<any> {
    return this.reservationsHelperService.getUpcomingReservation()
      .pipe(map(upcomingReservation => {
        const user: any = {
          userId: guest.id,
          displayName: `${guest.name} ${guest.surname}`,
          email: guest.username,
          imageLink: guest.profile_picture_url,
          deviceApnsType: environment.kommunicate.deviceApnsType,
          applicationId: environment.kommunicate.appId,
          metadata: {
            Guest: `${environment.adminPanelUrl}/reservations/guests/${guest.account_id}`
          }
        };

        if (upcomingReservation) {
          user.metadata.Reservation = this.toReservationDetails(upcomingReservation);
          user.metadata.Booking = this.getReservationLink(upcomingReservation);
        }

        return user;
      }));
  }

  private toReservationDetails(summary: ReservationSummary): string {
    const propertyName = !!summary.room_name ? `${summary.property_name} - ${summary.room_name}` : summary.property_name;
    return `at ${propertyName} between ${summary.check_in_date} and ${summary.check_out_date} (Ref: ${summary.booking_reference})`;
  }

  private getReservationLink(summary: ReservationSummary): string {
    const bookingRef = summary.booking_reference;

    switch (summary.type) {
      case ReservationType.LongLet:
        return `${environment.adminPanelUrl}/reservations/guest-long-lets?vague_reservation_id=${bookingRef}`;

      case ReservationType.ShortLet:
        return `${environment.adminPanelUrl}/reservations/guest-reservations?vague_reservation_id=${bookingRef}`;
    }
  }

}
