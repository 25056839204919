/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Reservation State
 */
export type ReservationState = 'CONFIRMED' | 'MODIFIED' | 'IN_ERROR' | 'WITHDRAWN' | 'CANCELLED' | 'CHECKED_OUT';

export const ReservationState = {
    Confirmed: 'CONFIRMED' as ReservationState,
    Modified: 'MODIFIED' as ReservationState,
    InError: 'IN_ERROR' as ReservationState,
    Withdrawn: 'WITHDRAWN' as ReservationState,
    Cancelled: 'CANCELLED' as ReservationState,
    CheckedOut: 'CHECKED_OUT' as ReservationState
};

