import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NetworkService} from '@shared/network/network.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

export class NetworkOfflineError implements Error {
  readonly name = 'NetworkOfflineError';
  readonly message = 'Unable to service request when user is offline';
}

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(private networkService: NetworkService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.networkService.isOnline()
      .pipe(switchMap(online => online ? this.handleOnline(req, next) : this.handleOffline()));
  }

  handleOnline(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  handleOffline(): Observable<HttpEvent<any>> {
    throw new NetworkOfflineError();
  }
}
