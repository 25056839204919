/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TaxiPrice { 
    price?: number;
    /**
     * The ID of the quotation that generated the price.  Quote this when booking to ensure same prices
     */
    provider_quotation_id?: string;
    /**
     * The waiting time, in minutes, for the taxi in the current quote to arrive.  This only applies for NOW/ASAP taxis
     */
    waiting_time?: number;
    /**
     * The vehicle type id
     */
    vehicle_type_id?: string;
}

