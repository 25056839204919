<ion-app>
  <ion-content id="splash" *ngIf="showSplashScreen">
    <app-splash></app-splash>
  </ion-content>

  <ion-router-outlet></ion-router-outlet>
</ion-app>



