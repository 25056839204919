import {Injectable} from '@angular/core';
import {ReservationSummary} from '@api/model/reservationSummary';
import {ReservationsCacheService} from '@shared/cache/reservations-cache.service';
import {sortActiveOrUpcomingReservations} from '@shared/reservations/reservations.helper';
import {Observable} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {first} from 'lodash';

@Injectable({providedIn: 'root'})
export class ReservationsHelperService {

  constructor(private reservationsCacheService: ReservationsCacheService) {
  }

  public getSortedActiveOrUpcomingCachedReservations(): Observable<ReservationSummary[]> {
    return this.reservationsCacheService.fetchCachedLinkedReservations()
      .pipe(delay(500), map(reservations => sortActiveOrUpcomingReservations(reservations)));
  }

  public getSortedActiveOrUpcomingReservations(): Observable<ReservationSummary[]> {
    return this.reservationsCacheService.getLinkedReservations()
      .pipe(delay(500), map(reservations => sortActiveOrUpcomingReservations(reservations)));
  }

  public getUpcomingReservation(): Observable<ReservationSummary> {
    return this.getSortedActiveOrUpcomingCachedReservations()
      .pipe(map(reservations => first(reservations)));
  }

}
