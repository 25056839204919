/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ZzzingServiceBookingCountType = 'USER_INPUT' | 'PROPERTY_BEDROOM_COUNT' | 'NONE';

export const ZzzingServiceBookingCountType = {
    UserInput: 'USER_INPUT' as ZzzingServiceBookingCountType,
    PropertyBedroomCount: 'PROPERTY_BEDROOM_COUNT' as ZzzingServiceBookingCountType,
    None: 'NONE' as ZzzingServiceBookingCountType
};

