import {Injectable} from '@angular/core';
import {GuestAuthService} from '@api/api/guestAuth.service';
import {GuestChangePasswordParams} from '@api/model/guestChangePasswordParams';
import {GuestForgotPasswordParams} from '@api/model/guestForgotPasswordParams';
import {LoginSuccessResult} from '@api/model/loginSuccessResult';
import {SocialConnection} from '@api/model/socialConnection';
import {ZzzingGuestUser} from '@api/model/zzzingGuestUser';
import {SocialLoginAuthorizationResult} from '@gen/api';
import {AnalyticsService, FirebaseZzzingEvents} from '@shared/analytics.service';
import {SessionService} from '@shared/auth/session.service';
import {SocialAuthService} from '@shared/auth/social-auth.service';
import {LoggingService} from '@shared/logging.service';
import {PushNotificationsService} from '@shared/push-notifications.service';
import {GuestChatService} from '@views/virtual-reception-services/chat/guest-chat.service';
import 'firebase/analytics';
import * as firebase from 'firebase/app';
import {isEmpty} from 'lodash';
import {Observable, throwError} from 'rxjs';
import {filter, switchMap, tap, throwIfEmpty} from 'rxjs/operators';
import EventName = firebase.analytics.EventName;

export interface ISignInCredentials {
  email: string;
  password: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private guestAuthService: GuestAuthService,
              private socialAuthService: SocialAuthService,
              private sessionService: SessionService,
              private pushNotificationsService: PushNotificationsService,
              private guestChatService: GuestChatService,
              private loggingService: LoggingService,
              private analyticsService: AnalyticsService) {
  }

  signIn(credentials: ISignInCredentials): Observable<LoginSuccessResult> {
    return this.guestAuthService.loginGuest({username: credentials.email, password: credentials.password})
      .pipe(switchMap(session => this.sessionService.login(session)),
        tap(session => this.initialiseServices(session)));
  }

  signOut(): Observable<void> {
    return this.sessionService.logout()
      .pipe(
        tap(() => this.guestChatService.logout().subscribe()),
        tap(() => this.analyticsService.logEvent(FirebaseZzzingEvents.LOGOUT, {}))
      );
  }

  renewAuth(): Observable<LoginSuccessResult> {
    return this.sessionService.clearAccessToken()
      .pipe(switchMap(() => this.sessionService.getRefreshToken()),
        filter(refreshToken => !!refreshToken),
        throwIfEmpty(() => new Error('No refresh token found to renew authentication')),
        switchMap(refreshToken => this.guestAuthService.renewAuthenticationGuest({refresh_token: refreshToken})),
        switchMap(session => this.sessionService.login(session)));
  }

  sendForgotPasswordEmail(forgotPasswordParams: GuestForgotPasswordParams): Observable<void> {
    return this.guestAuthService.forgotPasswordGuest(forgotPasswordParams);
  }

  changePassword(changePasswordParams: GuestChangePasswordParams): Observable<void> {
    return this.guestAuthService.changePasswordGuest(changePasswordParams);
  }

  updateUser(userDetails: Partial<ZzzingGuestUser>): Observable<ZzzingGuestUser> {
    return this.sessionService.updateUser(userDetails);
  }

  getUser(): Observable<ZzzingGuestUser> {
    return this.sessionService.getUser();
  }

  getAccessToken(): Observable<string> {
    return this.sessionService.getAccessToken();
  }

  isLoggedIn(): Observable<boolean> {
    return this.sessionService.isLoggedIn();
  }

  socialLogin(): Observable<LoginSuccessResult> {
    const authorizationCode = this.socialAuthService.getSocialLoginAuthorizationCode();

    if (isEmpty(authorizationCode)) {
      return throwError('Social Login Authorization Code is empty');
    }

    const redirectUri = this.socialAuthService.getSocialLoginRedirectUri();
    const socialLoginParams = {authorization_code: authorizationCode, authorization_redirect_uri: redirectUri};
    return this.guestAuthService.socialLoginGuest(socialLoginParams)
      .pipe(switchMap(session => this.sessionService.login(session)),
        tap(session => this.initialiseServices(session)));
  }

  getSocialLoginAuthorization(socialConnection: SocialConnection): Observable<SocialLoginAuthorizationResult> {
    const redirectUri = this.socialAuthService.getSocialLoginRedirectUri();
    const socialLoginAuthorizationParams = {social_connection: socialConnection, redirect_uri: redirectUri};
    return this.guestAuthService.getGuestSocialLoginAuthorization(socialLoginAuthorizationParams);
  }

  private initialiseServices(session: LoginSuccessResult): void {
    this.loggingService.setUserId(session.user.account_id);
    this.analyticsService.setUser(session.user.account_id);
    this.analyticsService.logEvent(EventName.LOGIN, {});
    this.pushNotificationsService.initialise()
      .pipe(switchMap(() => this.guestChatService.login(session.user)))
      .subscribe();
  }
}
