import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationPopoverComponent} from './notification-popover.component';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [
    NotificationPopoverComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  providers: [],
  exports: [
    NotificationPopoverComponent
  ]
})

export class ComponentNotificationPopoverModule { }
