/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaxiBookingSummaryField = 'NUMBER_OF_PASSENGERS' | 'DRIVER_NOTES' | 'VEHICLE_DRIVER_NAME' | 'VEHICLE_MOBILE_NUMBER' | 'VEHICLE_REGISTRATION_NUMBER' | 'VEHICLE_TYPE' | 'PICKUP_ADDRESS' | 'PICKUP_LATITUDE' | 'PICKUP_LONGITUDE' | 'DROPOFF_ADDRESS' | 'DROPOFF_LATITUDE' | 'DROPOFF_LONGITUDE';

export const TaxiBookingSummaryField = {
    NumberOfPassengers: 'NUMBER_OF_PASSENGERS' as TaxiBookingSummaryField,
    DriverNotes: 'DRIVER_NOTES' as TaxiBookingSummaryField,
    VehicleDriverName: 'VEHICLE_DRIVER_NAME' as TaxiBookingSummaryField,
    VehicleMobileNumber: 'VEHICLE_MOBILE_NUMBER' as TaxiBookingSummaryField,
    VehicleRegistrationNumber: 'VEHICLE_REGISTRATION_NUMBER' as TaxiBookingSummaryField,
    VehicleType: 'VEHICLE_TYPE' as TaxiBookingSummaryField,
    PickupAddress: 'PICKUP_ADDRESS' as TaxiBookingSummaryField,
    PickupLatitude: 'PICKUP_LATITUDE' as TaxiBookingSummaryField,
    PickupLongitude: 'PICKUP_LONGITUDE' as TaxiBookingSummaryField,
    DropoffAddress: 'DROPOFF_ADDRESS' as TaxiBookingSummaryField,
    DropoffLatitude: 'DROPOFF_LATITUDE' as TaxiBookingSummaryField,
    DropoffLongitude: 'DROPOFF_LONGITUDE' as TaxiBookingSummaryField
};

