/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TourBookingSummaryField = 'TOUR_ID' | 'PICKUP_TITLE' | 'PICKUP_LATITUDE' | 'PICKUP_LONGITUDE' | 'DROPOFF_TITLE' | 'DROPOFF_LATITUDE' | 'DROPOFF_LONGITUDE';

export const TourBookingSummaryField = {
    TourId: 'TOUR_ID' as TourBookingSummaryField,
    PickupTitle: 'PICKUP_TITLE' as TourBookingSummaryField,
    PickupLatitude: 'PICKUP_LATITUDE' as TourBookingSummaryField,
    PickupLongitude: 'PICKUP_LONGITUDE' as TourBookingSummaryField,
    DropoffTitle: 'DROPOFF_TITLE' as TourBookingSummaryField,
    DropoffLatitude: 'DROPOFF_LATITUDE' as TourBookingSummaryField,
    DropoffLongitude: 'DROPOFF_LONGITUDE' as TourBookingSummaryField
};

