import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {ProfileService} from '@api/api/profile.service';
import {FCM} from '@capacitor-community/fcm';
import {Plugins, PushNotification} from '@capacitor/core';
import {Platform} from '@ionic/angular';
import {AnalyticsService, FirebaseZzzingEvents} from '@shared/analytics.service';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

const fcm = new FCM();

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private profileService: ProfileService,
              private platform: Platform,
              private ngZone: NgZone,
              private router: Router,
              private analyticsService: AnalyticsService) {
  }

  addPushNotificationListener(): void {
    if (this.platform.is('hybrid')) {
      Plugins.PushNotifications.addListener('pushNotificationReceived', notification => this.handleNotificationReceived(notification));
      Plugins.PushNotifications.addListener('pushNotificationActionPerformed',
        notification => this.handleNotificationActionPerformed(notification?.notification?.title, notification?.notification?.data?.link));
      Plugins.LocalNotifications.addListener('localNotificationActionPerformed',
        notification => this.handleNotificationActionPerformed(notification?.notification?.title, notification?.notification?.extra?.link));
    }
  }

  initialise(): Observable<void> {
    if (this.platform.is('hybrid')) {
      const setupPushNotificationsPromise =
        this.setupPushNotifications().catch(error => console.error('Could not initialise Push Notifications service.', error));
      return fromPromise(setupPushNotificationsPromise);
    }

    return of(null);
  }

  private setupPushNotifications(): Promise<void> {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not; Android will just grant without prompting
    return Plugins.PushNotifications.requestPermission().then(permission => permission.granted ? this.register() : Promise.resolve());
  }

  private register(): Promise<void> {
    // Register with Apple / Google to receive push via APNS/FCM
    return Plugins.PushNotifications.register()
      .then(() => fcm.getToken())
      .then(deviceToken => this.storeToken(deviceToken.token));
  }

  private storeToken(deviceToken: string): Promise<void> {
    return this.profileService.updateGuestDeviceDetails({token: deviceToken}).toPromise();
  }

  private handleNotificationReceived(notification: PushNotification): void {
    // Notification received whilst app is open => we submit a local push notification
    Plugins.LocalNotifications.schedule({
      notifications: [{
        id: new Date().getUTCMilliseconds(),
        title: notification.title,
        body: notification.body,
        extra: notification.data,
        schedule: {at: moment().add(5, 'second').toDate()}
      }]
    }).catch((error) => console.error('Could not schedule Local Push Notification', notification, error));
  }

  private handleNotificationActionPerformed(title: string, link: string): void {
    this.analyticsService.logEvent(FirebaseZzzingEvents.PUSH_NOTIFICATION_OPENED, {title, link});
    if (link) {
      this.ngZone.run(() => this.router.navigateByUrl(link, {replaceUrl: true}));
    }
  }
}
