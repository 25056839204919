/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PropertyAmenityType = 'KITCHEN' | 'BATHROOM' | 'LIVING' | 'OUTDOOR' | 'GENERAL' | 'SPECIAL_FEATURES' | 'CLEANING_LAUNDRY' | 'SAFETY';

export const PropertyAmenityType = {
    Kitchen: 'KITCHEN' as PropertyAmenityType,
    Bathroom: 'BATHROOM' as PropertyAmenityType,
    Living: 'LIVING' as PropertyAmenityType,
    Outdoor: 'OUTDOOR' as PropertyAmenityType,
    General: 'GENERAL' as PropertyAmenityType,
    SpecialFeatures: 'SPECIAL_FEATURES' as PropertyAmenityType,
    CleaningLaundry: 'CLEANING_LAUNDRY' as PropertyAmenityType,
    Safety: 'SAFETY' as PropertyAmenityType
};

