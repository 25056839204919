import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Platform} from '@ionic/angular';
import {AnalyticsService} from '@shared/analytics.service';
import {SocialAuthService} from '@shared/auth/social-auth.service';
import {LangService} from '@shared/lang.service';
import {NetworkService} from '@shared/network/network.service';
import {PushNotificationsService} from '@shared/push-notifications.service';

const {SplashScreen} = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('splash') splash: ElementRef;
  showSplashScreen = true;

  constructor(private langService: LangService,
              private renderer: Renderer2,
              private platform: Platform,
              private statusBar: StatusBar,
              private screenOrientation: ScreenOrientation,
              private networkService: NetworkService,
              private pushNotificationsService: PushNotificationsService,
              private analyticsService: AnalyticsService,
              private socialAuthService: SocialAuthService) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.langService.init();
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      if (this.platform.is('hybrid')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        setTimeout(() => SplashScreen.hide(), 500); // Hiding the mobile app splash screen (i.e. not our app-splash)
        setTimeout(() => this.showSplashScreen = false, 2750); // Timing matches css animation in app-splash
      } else {
        this.showSplashScreen = false;
      }

      this.networkService.addNetworkStatusListener();
      this.pushNotificationsService.addPushNotificationListener();
      this.analyticsService.initialise();
      this.socialAuthService.addSocialLoginRedirectListener();
    });
  }

}
