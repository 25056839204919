/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A guest preference question
 */
export type PreferenceQuestion = 'ENJOY_ADVENTURE' | 'ENJOY_CUISINE' | 'ENJOY_CULTURE' | 'ENJOY_RELAXING';

export const PreferenceQuestion = {
    Adventure: 'ENJOY_ADVENTURE' as PreferenceQuestion,
    Cuisine: 'ENJOY_CUISINE' as PreferenceQuestion,
    Culture: 'ENJOY_CULTURE' as PreferenceQuestion,
    Relaxing: 'ENJOY_RELAXING' as PreferenceQuestion
};

