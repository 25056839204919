import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {GlideComponent} from './glide/glide.component';

@NgModule({
  declarations: [
    GlideComponent
  ],
  imports: [
    CommonModule,
    SwiperModule
  ],
  providers: [],
  exports: [
    GlideComponent
  ]
})

export class ComponentsCarouselModule {
}
