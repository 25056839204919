/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CancellationPolicyType = 'ADVANCED' | 'SIMPLE' | 'NON_REFUNDABLE' | 'FULL_REFUND';

export const CancellationPolicyType = {
    Advanced: 'ADVANCED' as CancellationPolicyType,
    Simple: 'SIMPLE' as CancellationPolicyType,
    NonRefundable: 'NON_REFUNDABLE' as CancellationPolicyType,
    FullRefund: 'FULL_REFUND' as CancellationPolicyType
};

