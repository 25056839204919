import {Component} from '@angular/core';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html'
})
export class ViewsComponent {

  constructor() {
    // If you have landing page, remove below line and implement it here.
  }

}
