import {Pipe, PipeTransform} from '@angular/core';
import {AdvancedPolicyRule} from '@api/model/advancedPolicyRule';
import {CancellationPolicyType} from '@api/model/cancellationPolicyType';
import {GuestServiceCancellationPolicy} from '@api/model/guestServiceCancellationPolicy';
import {TranslateService} from '@ngx-translate/core';

/**
 * A pipe to be used to output a guest service cancellation policy in a friendly way.
 */
@Pipe({
  name: 'cancellation_policy'
})
export class CancellationPolicyPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(cancellationPolicy: GuestServiceCancellationPolicy): string {
    if (cancellationPolicy.policy_type === CancellationPolicyType.Advanced) {
      const advancedPolicyInfo = cancellationPolicy.advanced_policy_info;
      if (advancedPolicyInfo.rules.length > 1) {
        const rulePoints = advancedPolicyInfo.rules
          .map(rule => `
            <li>${this.buildRuleString(rule)}</li>`
          ).join(' ');
        return `<ul>${rulePoints}</ul>`;
      } else {
        return this.buildRuleString(advancedPolicyInfo.rules[0]);
      }
    } else if (cancellationPolicy.policy_type === CancellationPolicyType.Simple
      || cancellationPolicy.policy_type === CancellationPolicyType.FullRefund) {
      return cancellationPolicy.title;
    }

    return 'N/A';
  }

  private buildRuleString(rule: AdvancedPolicyRule): string {
    return this.translate.instant('cancellation-policy.advanced-rule', {
      charge: rule.charge,
      cutoff: this.getCutoffString(rule)
    });
  }

  getCutoffString(rule: AdvancedPolicyRule): string {
    if (rule.cutoff_hours === 1) {
      return `1 ${this.translate.instant('cancellation-policy.hour')}`;
    } else if (rule.cutoff_hours < 24) {
      return `${rule.cutoff_hours} ${this.translate.instant('cancellation-policy.hours')}`;
    } else if (rule.cutoff_hours === 24) {
      return `1 ${this.translate.instant('cancellation-policy.day')}`;
    } else if (rule.cutoff_hours % 24 === 0) {
      return `${rule.cutoff_hours / 24} ${this.translate.instant('cancellation-policy.days')}`;
    } else {
      return `${Math.floor(rule.cutoff_hours / 24)} ${this.translate.instant('cancellation-policy.days')}
      ${rule.cutoff_hours % 24} ${this.translate.instant('cancellation-policy.hours')}`;
    }

  }

}
