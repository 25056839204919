import {environment} from '@env/environment';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {TypePolicies} from '@apollo/client/cache/inmemory/policies';

const INMEMORY_CACHE_TYPE_POLICIES: TypePolicies = {
  GuestAppLegalInfo: {
    merge: true,
  }
};

export const ApolloGraphqlConfig: (httpLink: HttpLink) => any = (httpLink: HttpLink) => {
  return {
    cache: new InMemoryCache({
      typePolicies: INMEMORY_CACHE_TYPE_POLICIES
    }),
    link: httpLink.create({
      uri: environment.strapi_base_url + '/graphql'
    })
  };
};
