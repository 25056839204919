import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '@env/environment';
import {Router} from '@angular/router';
import {PageUrls} from '@shared/page-urls';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {

  private adminRoot = environment.adminRoot;

  constructor(private router: Router) { }

  ngOnInit(): void {
    document.body.classList.add('background');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('background');
  }


  goHome(): void {
    this.router.navigate([PageUrls.APP_ROOT]);
  }
}
