import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PipesModule} from '@app/pipes/pipes.module';
import {ErrorComponent} from '@app/views/error/error.component';
import {ComponentNotificationPopoverModule} from '@components/notification-popover/component.notification-popover.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [ErrorComponent],
  imports: [
    RouterModule,
    ComponentNotificationPopoverModule,
    CommonModule,
    TranslateModule,
    PipesModule
  ],
  exports: [
    RouterModule,
    ErrorComponent,
    TranslateModule,
    CommonModule,
    PipesModule
  ]
})
export class SharedModule { }
