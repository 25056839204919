/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of the property
 */
export type PropertyType = 'APARTMENT' | 'BED_AND_BREAKFAST' | 'CHALET' | 'GUEST_HOUSE' | 'HOTEL' | 'LODGE' | 'RESORT' | 'VILLA' | 'CASTLE' | 'APARTHOTEL' | 'BOAT' | 'COTTAGE' | 'CAMPING' | 'HOUSE';

export const PropertyType = {
    Apartment: 'APARTMENT' as PropertyType,
    BedAndBreakfast: 'BED_AND_BREAKFAST' as PropertyType,
    Chalet: 'CHALET' as PropertyType,
    GuestHouse: 'GUEST_HOUSE' as PropertyType,
    Hotel: 'HOTEL' as PropertyType,
    Lodge: 'LODGE' as PropertyType,
    Resort: 'RESORT' as PropertyType,
    Villa: 'VILLA' as PropertyType,
    Castle: 'CASTLE' as PropertyType,
    Aparthotel: 'APARTHOTEL' as PropertyType,
    Boat: 'BOAT' as PropertyType,
    Cottage: 'COTTAGE' as PropertyType,
    Camping: 'CAMPING' as PropertyType,
    House: 'HOUSE' as PropertyType
};

