import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export class UpgradeRequiredError implements Error {
  readonly name = 'UpgradeRequiredError';
  readonly message = 'An application upgrade is required to serve request.';
}

@Injectable()
export class UpgradeInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => this.handleError(error)));
  }

  handleError(error: any): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 426:
          return throwError(new UpgradeRequiredError());
        default:
          break;
      }
    }

    return throwError(error);
  }

}
