/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A tour promotion type
 */
export type TourPromotionType = 'NEW' | 'FEATURED' | 'TRENDING' | 'MOST_POPULAR';

export const TourPromotionType = {
    New: 'NEW' as TourPromotionType,
    Featured: 'FEATURED' as TourPromotionType,
    Trending: 'TRENDING' as TourPromotionType,
    MostPopular: 'MOST_POPULAR' as TourPromotionType
};

