import {ErrorHandler, Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BackButtonService} from '@shared/back-button.service';
import {LoggingService} from '@shared/logging.service';
import {NetworkOfflineError} from '@shared/network/network.interceptor';
import {NotificationsService} from '@shared/notifications.service';
import {UpgradeRequiredError} from '@shared/upgrade/upgrade.interceptor';

const {NativeMarket} = Plugins;

export class ComingSoonError implements Error {
  readonly name = 'ComingSoonError';
  readonly message = 'This feature is not yet implemented and is coming soon.';
}

@Injectable()
export class AppErrorHandler extends ErrorHandler {

  constructor(private notifications: NotificationsService,
              private alertController: AlertController,
              private backButtonService: BackButtonService,
              private translate: TranslateService,
              private loggingService: LoggingService) {
    super();
  }

  public handleError(e: any): void {
    if (e instanceof NetworkOfflineError) {
      this.handleNetworkOfflineError();
    } else if (e instanceof UpgradeRequiredError) {
      this.handleUpgradeRequiredError();
    } else if (e instanceof ComingSoonError) {
      this.handleComingSoonError();
    } else {
      this.notifications.error(this.translate.instant('error.generic'));
      this.loggingService.logError(e);
    }

    super.handleError(e);
  }

  private handleNetworkOfflineError(): void {
    this.dismissAnyExistingAlert();

    this.alertController.create({
      header: this.translate.instant('error.header'),
      message: this.translate.instant('network.offline-alert'),
      backdropDismiss: false,
      buttons: [this.getBackButton()]
    }).then(alertElement => alertElement.present());
  }

  private handleUpgradeRequiredError(): void {
    this.dismissAnyExistingAlert();

    this.alertController.create({
      header: this.translate.instant('upgrade.header'),
      message: this.translate.instant('upgrade.message'),
      backdropDismiss: false,
      buttons: [this.getOpenStoreButton(), this.getBackButton()]
    }).then(alertElement => alertElement.present());
  }

  private handleComingSoonError(): void {
    this.dismissAnyExistingAlert();

    this.alertController.create({
      header: this.translate.instant('coming-soon.header'),
      message: this.translate.instant('coming-soon.message'),
      backdropDismiss: false,
      buttons: [this.getBackButton()]
    }).then(alertElement => alertElement.present());
  }

  private getBackButton(): any {
    return {
      text: this.translate.instant('buttons.back'),
      handler: () => this.backButtonService.goBack()
    };
  }

  private getOpenStoreButton(): any {
    return {
      text: this.translate.instant('upgrade.open-store'),
      handler: () => NativeMarket.openStoreListing({appId: 'com.vh.zzzing'})
    };
  }

  private dismissAnyExistingAlert(): void {
    this.alertController.getTop().then(existingAlert => {
      if (existingAlert) {
        this.alertController.dismiss();
      }
    });
  }
}
