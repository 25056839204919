import {TitleCasePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

/**
 * A pipe to be used to convert enumerations into title case e.g. NORMAL_RATE = Normal Rate.
 */
@Pipe({
  name: 'enumeration'
})
export class EnumPipe implements PipeTransform {

  constructor(private titleCasePipe: TitleCasePipe) {
  }

  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value.split('_')
      .map(stringPart => this.titleCasePipe.transform(stringPart))
      .join(' ');
  }

}
