/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationPinDetails { 
    applicable_property_entrance_pin?: boolean;
    /**
     * The property entrance pin code
     */
    property_entrance_pin?: string;
    applicable_common_entrance_pin?: boolean;
    /**
     * The pin code for the common entrance
     */
    common_entrance_pin?: string;
}

