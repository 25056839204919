import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ViewsComponent} from '@app/views/views.component';
import {ViewRoutingModule} from '@app/views/views.routing';
import {ComponentsCarouselModule} from '@components/carousel/components.carousel.module';

import {IonicModule} from '@ionic/angular';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {SharedModule} from '@shared/shared.module';
import {TabsModule} from 'ngx-bootstrap/tabs';

@NgModule({
  declarations: [ViewsComponent],
  imports: [
    CommonModule,
    ViewRoutingModule,
    SharedModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    ScrollToModule.forRoot(),
    IonicModule
  ]
})
export class ViewsModule { }
