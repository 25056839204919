const LANGUAGE_THEME_KEY = 'zzzing_app_lang';

export const setThemeColor = (color) => {
  try {
    return setActiveTheme(color);
  } catch (error) {
    console.log(">>>> src/app/utils/util.js : setThemeColor -> error", error)
  }
}

export const getThemeLang = () => {
    let lang = 'en-US';
    try {
        lang = localStorage.getItem(LANGUAGE_THEME_KEY) || 'en-US';
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeLang -> error", error)
        lang = 'en-US'
    }
    return lang;
}
export const setThemeLang = (lang) => {
    try {
        localStorage.setItem(LANGUAGE_THEME_KEY, lang);
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeLang -> error", lang)
    }
}

/**
 * Sets the active stylesheet in the document head. The theme stylesheets that
 * may be loaded here are those that are declared in the angular.json and which
 * are bundled and served lazily as css (i.e. dark.zzzing.css, light.zzzing.css).
 *
 * @param color the color theme.
 * @returns {HTMLElement} the stylesheet element containing the styles for the active theme.
 */
function setActiveTheme(color) {
    const themeElement = document.getElementById('theme');

    if (themeElement) {
        themeElement.href = `${color}.css`;
        return themeElement;
    } else {
        const newThemeElement = document.createElement('link');
        newThemeElement.id = 'theme';
        newThemeElement.rel = 'stylesheet';
        newThemeElement.href = `${color}.css`;

        const head = document.head;
        head.appendChild(newThemeElement);

        return newThemeElement;
    }
}
