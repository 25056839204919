/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortOrder = 'ASCENDING' | 'DESCENDING';

export const SortOrder = {
    Ascending: 'ASCENDING' as SortOrder,
    Descending: 'DESCENDING' as SortOrder
};

