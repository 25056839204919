/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The airport transfer type
 */
export type AirportTransferType = 'ARRIVAL' | 'DEPARTURE' | 'ROUND_TRIP';

export const AirportTransferType = {
    Arrival: 'ARRIVAL' as AirportTransferType,
    Departure: 'DEPARTURE' as AirportTransferType,
    RoundTrip: 'ROUND_TRIP' as AirportTransferType
};

