export * from './accommodation.service';
import { AccommodationService } from './accommodation.service';
export * from './airportTransfers.service';
import { AirportTransfersService } from './airportTransfers.service';
export * from './guestAuth.service';
import { GuestAuthService } from './guestAuth.service';
export * from './guestCommercialDocuments.service';
import { GuestCommercialDocumentsService } from './guestCommercialDocuments.service';
export * from './guestReservations.service';
import { GuestReservationsService } from './guestReservations.service';
export * from './guestServices.service';
import { GuestServicesService } from './guestServices.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './profilePaymentDetails.service';
import { ProfilePaymentDetailsService } from './profilePaymentDetails.service';
export * from './taxis.service';
import { TaxisService } from './taxis.service';
export * from './tours.service';
import { ToursService } from './tours.service';
export * from './virtualReception.service';
import { VirtualReceptionService } from './virtualReception.service';
export * from './zzzingServices.service';
import { ZzzingServicesService } from './zzzingServices.service';
export const APIS = [AccommodationService, AirportTransfersService, GuestAuthService, GuestCommercialDocumentsService, GuestReservationsService, GuestServicesService, ProfileService, ProfilePaymentDetailsService, TaxisService, ToursService, VirtualReceptionService, ZzzingServicesService];
