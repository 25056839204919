import {Injectable} from '@angular/core';
import {LoginSuccessResult} from '@api/model/loginSuccessResult';
import {ZzzingGuestUser} from '@api/model/zzzingGuestUser';
import {Plugins} from '@capacitor/core';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {map, switchMap} from 'rxjs/operators';

export const AUTH_ACCESS_TOKEN_KEY = 'access_token';
export const AUTH_REFRESH_TOKEN_KEY = 'refresh_token';
export const USER_ID_KEY = 'user_id';
export const ACCOUNT_ID_KEY = 'account_id';
export const USERNAME_KEY = 'username';
export const NAME_KEY = 'name';
export const SURNAME_KEY = 'surname';
export const PICTURE_KEY = 'pictureUrl';
export const SOCIAL_CONNECTION  = 'socialConnection';

@Injectable({providedIn: 'root'})
export class SessionService {

  constructor() {
  }

  login(session: LoginSuccessResult): Observable<LoginSuccessResult> {
    const storeSessionPromise = Promise.all([
      Plugins.Storage.set({key: AUTH_ACCESS_TOKEN_KEY, value: session.access_token}),
      Plugins.Storage.set({key: AUTH_REFRESH_TOKEN_KEY, value: session.refresh_token}),
      Plugins.Storage.set({key: USER_ID_KEY, value: session.user?.id}),
      Plugins.Storage.set({key: ACCOUNT_ID_KEY, value: session.user?.account_id}),
      Plugins.Storage.set({key: USERNAME_KEY, value: session.user?.username}),
      Plugins.Storage.set({key: NAME_KEY, value: session.user?.name}),
      Plugins.Storage.set({key: SURNAME_KEY, value: session.user?.surname}),
      Plugins.Storage.set({key: PICTURE_KEY, value: session.user?.profile_picture_url}),
      Plugins.Storage.set({key: SOCIAL_CONNECTION, value: session.user?.social_connection || ''})
    ]).then(() => session);

    return fromPromise(storeSessionPromise);
  }

  logout(): Observable<void> {
    return fromPromise(Plugins.Storage.clear());
  }

  getRefreshToken(): Observable<string> {
    const getRefreshTokenPromise = Plugins.Storage.get({key: AUTH_REFRESH_TOKEN_KEY}).then((token) => this.parse(token.value));
    return fromPromise(getRefreshTokenPromise);
  }

  getAccessToken(): Observable<string> {
    const getAccessTokenPromise = Plugins.Storage.get({key: AUTH_ACCESS_TOKEN_KEY}).then((token) => this.parse(token.value));
    return fromPromise(getAccessTokenPromise);
  }

  clearAccessToken(): Observable<void> {
    return fromPromise(Plugins.Storage.remove({key: AUTH_ACCESS_TOKEN_KEY}));
  }

  isLoggedIn(): Observable<boolean> {
    return this.getAccessToken().pipe(map(token => !!token));
  }

  getUser(): Observable<ZzzingGuestUser> {
    const getUserPromise = Promise.all([
      Plugins.Storage.get({key: USER_ID_KEY}),
      Plugins.Storage.get({key: ACCOUNT_ID_KEY}),
      Plugins.Storage.get({key: USERNAME_KEY}),
      Plugins.Storage.get({key: NAME_KEY}),
      Plugins.Storage.get({key: SURNAME_KEY}),
      Plugins.Storage.get({key: PICTURE_KEY}),
      Plugins.Storage.get({key: SOCIAL_CONNECTION})
    ]).then(([userId, accountId, username, name, surname, pictureUrl, socialConnection]) => {
      return {
        id: this.parse(userId.value),
        account_id: this.parse(accountId.value),
        username: this.parse(username.value),
        name: this.parse(name.value),
        surname: this.parse(surname.value),
        profile_picture_url: this.parse(pictureUrl.value),
        social_connection: socialConnection.value?.length > 0 ? this.parse(socialConnection.value) : null
      } as ZzzingGuestUser;
    });

    return fromPromise(getUserPromise);
  }

  updateUser(userDetails: Partial<ZzzingGuestUser>): Observable<ZzzingGuestUser> {
    const updateUserPromises = [];

    if (userDetails.name) {
      updateUserPromises.push(Plugins.Storage.set({key: NAME_KEY, value: userDetails.name}));
    }

    if (userDetails.surname) {
      updateUserPromises.push(Plugins.Storage.set({key: SURNAME_KEY, value: userDetails.surname}));
    }

    if (userDetails.profile_picture_url) {
      updateUserPromises.push(Plugins.Storage.set({key: PICTURE_KEY, value: userDetails.profile_picture_url}));
    }

    return fromPromise(Promise.all(updateUserPromises)).pipe(switchMap(() => this.getUser()));
  }

  parse(value?: string): string {
    return value || '';
  }

}

