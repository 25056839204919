/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The issue catagery
 */
export type IssueCategory = 'PROPERTY_ACCESS' | 'WIFI' | 'MALFUNCTIONING_ITEMS' | 'MISSING_EQUIPMENT' | 'CLEANLINESS' | 'SECURITY' | 'GENERAL';

export const IssueCategory = {
    PropertyAccess: 'PROPERTY_ACCESS' as IssueCategory,
    Wifi: 'WIFI' as IssueCategory,
    MalfunctioningItems: 'MALFUNCTIONING_ITEMS' as IssueCategory,
    MissingEquipment: 'MISSING_EQUIPMENT' as IssueCategory,
    Cleanliness: 'CLEANLINESS' as IssueCategory,
    Security: 'SECURITY' as IssueCategory,
    General: 'GENERAL' as IssueCategory
};

