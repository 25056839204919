import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {ToastOptions} from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private toastController: ToastController) {
  }

  success(successMessage: string): void {
    this.createNotification({message: successMessage, color: 'success', buttons: [{icon: 'checkmark-circle-outline'}]});
  }

  error(errorMessage: string): void {
    this.createNotification({message: errorMessage, color: 'danger', buttons: [{icon: 'alert-circle-outline'}]});
  }

  info(infoMessage: string): void {
    this.createNotification({message: infoMessage, color: 'primary', buttons: [{icon: 'information-circle-outline'}]});
  }

  createNotification(notificationOptions: ToastOptions): void {
    this.dismissExistingNotification();

    this.toastController.create({...notificationOptions, duration: 5000, position: 'bottom', keyboardClose: true})
      .then(toastElement => toastElement.present());
  }

  private dismissExistingNotification(): void {
    this.toastController.getTop().then(existingNotification => {
      if (existingNotification) {
        this.toastController.dismiss();
      }
    });
  }
}
