import {Injectable} from '@angular/core';
import {NetworkStatus, Plugins} from '@capacitor/core';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '@shared/notifications.service';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {map, tap} from 'rxjs/operators';

export const NETWORK_STATUS_KEY = 'NETWORK_STATUS';

@Injectable({providedIn: 'root'})
export class NetworkService {

  constructor(private notifications: NotificationsService,
              private translate: TranslateService) {
  }

  public isOnline(): Observable<boolean> {
    return fromPromise(Plugins.Network.getStatus()).pipe(map(status => status.connected));
  }

  public addNetworkStatusListener(): void {
    Plugins.Network.addListener('networkStatusChange', status => this.handleNetworkStatusChange(status));
  }

  private handleNetworkStatusChange(newStatus: NetworkStatus): void {
    this.getStoredNetworkStatus()
      .pipe(tap(() => this.setStoredNetworkStatus(newStatus)))
      .subscribe((storedStatus) => {
        // we always have a storedStatus, if we don't we assume we are connected (@see getStoredNetworkStatus)
        if (storedStatus.connected !== newStatus.connected) {
          this.showNetworkStatusChangeNotification(newStatus);
        }
      });
  }

  private showNetworkStatusChangeNotification(status: NetworkStatus): void {
    this.notifications.info(this.getNetworkStatusMessage(status));
  }

  private getNetworkStatusMessage(status: NetworkStatus): string {
    return this.translate.instant(status.connected ? 'network.online-notification' : 'network.offline-notification');
  }

  private setStoredNetworkStatus(status: NetworkStatus): Observable<void> {
    const setNetworkStatusPromise = Plugins.Storage.set({key: NETWORK_STATUS_KEY, value: JSON.stringify(status)});
    return fromPromise(setNetworkStatusPromise);
  }

  private getStoredNetworkStatus(): Observable<NetworkStatus> {
    const getNetworkStatusPromise =
      Plugins.Storage.get({key: NETWORK_STATUS_KEY}).then(networkStatus => this.parseOrGetDefault(networkStatus.value));
    return fromPromise(getNetworkStatusPromise);
  }

  private parseOrGetDefault(value: string): NetworkStatus {
    return value ? JSON.parse(value) as NetworkStatus : {connected: true, connectionType: 'unknown'};
  }
}
