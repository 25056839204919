import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Plugins} from '@capacitor/core';
import {environment} from '@env/environment';
import {Platform} from '@ionic/angular';
import {BrowserService} from '@shared/browser/browser.service';
import {PageUrls} from '@shared/page-urls';
import {cloneDeep} from 'lodash';

const {App} = Plugins;

@Injectable({providedIn: 'root'})
export class SocialAuthService {

  constructor(private browserService: BrowserService,
              private platform: Platform,
              private router: Router) {
  }

  private socialLoginAuthorizationCode: string;

  addSocialLoginRedirectListener(): void {
    if (this.platform.is('hybrid')) {
      // for mobile, we add a listener for when the app is opened using the custom url scheme
      App.addListener('appUrlOpen', data => {
        this.browserService.closeInAppBrowser();
        const url = data.url;
        if (url.includes(PageUrls.SOCIAL_LOGIN)) {
          const socialLoginUrl = `#/${PageUrls.SOCIAL_LOGIN}`;
          const queryParams = url.split(this.mobileBaseUrl).pop().split(socialLoginUrl).shift();
          this.setSocialLoginAuthorizationCode(new URLSearchParams(queryParams).get('code'));
          this.router.navigate([PageUrls.SOCIAL_LOGIN]);
        }
      });
    } else {
      // for web, the code below is always executed when the app is reopened (navigation is be handled automatically)
      this.setSocialLoginAuthorizationCode(new URLSearchParams(location.search).get('code'));
    }
  }

  getSocialLoginAuthorizationCode(): string {
    const authorizationCode = cloneDeep(this.socialLoginAuthorizationCode);
    this.socialLoginAuthorizationCode = null;
    return authorizationCode;
  }

  setSocialLoginAuthorizationCode(authorizationCode: string): void {
    this.socialLoginAuthorizationCode = authorizationCode;
  }

  getSocialLoginRedirectUri(): string {
    // for mobile, we use the appId custom url scheme
    const baseUrl = this.platform.is('hybrid') ? this.mobileBaseUrl : this.webBaseUrl;
    return `${baseUrl}#/${PageUrls.SOCIAL_LOGIN}`;
  }

  private get mobileBaseUrl(): string {
    return `${environment.appId}://`;
  }

  private get webBaseUrl(): string {
    return `${location.origin}/`;
  }
}
