import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '@shared/auth/auth.service';
import {PageUrls} from '@shared/page-urls';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // This guard is used to logout the user by ensuring that we have a token.
    // This check is only carried out ONCE when our main protected route (i.e. /app) is activated.
    // Any navigation to a child route of this path will not trigger the check.
    // We do not implement canActivateChild since we are already safeguarded by the AuthInterceptor.
    return this.authService.isLoggedIn()
      .pipe(tap((isLoggedIn: boolean) => this.handleLoggedOutRedirect(isLoggedIn)));
  }

  handleLoggedOutRedirect(isLoggedIn: boolean): void {
    if (!isLoggedIn) {
      this.router.navigate([PageUrls.USER_ROOT]).then(() => this.authService.signOut().subscribe());
    }
  }
}
