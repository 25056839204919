import {ReservationDetails} from '@api/model/reservationDetails';
import {ReservationState} from '@api/model/reservationState';
import {ReservationSummary} from '@api/model/reservationSummary';
import {ReservationFriendlyState} from '@views/reservation-details/state/reservation-friendly-state';
import * as moment from 'moment';

export const toReservationFriendlyState = (reservation: ReservationDetails | ReservationSummary): ReservationFriendlyState => {
  switch (reservation.state) {
    case ReservationState.Confirmed:
    case ReservationState.Modified:
      return isReservationInProgress(reservation) ? ReservationFriendlyState.ACTIVE : ReservationFriendlyState.UPCOMING;
    case ReservationState.CheckedOut:
      return ReservationFriendlyState.COMPLETED;
    case ReservationState.Withdrawn:
    case ReservationState.Cancelled:
      return ReservationFriendlyState.CANCELLED;
    case ReservationState.InError:
      // Guests should not be able to view In Error Reservations
      throw Error('Reservation is not available at the moment');
  }
};

function isReservationInProgress(reservation: ReservationDetails | ReservationSummary): boolean {
  return moment(reservation.check_in_date).startOf('day').isSameOrBefore() &&
    moment(reservation.check_out_date).endOf('day').isSameOrAfter();
}
