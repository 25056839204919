import {Injectable} from '@angular/core';
import {GuestReservationsService} from '@api/api/guestReservations.service';
import {ReservationDetails} from '@api/model/reservationDetails';
import {ReservationSummary} from '@api/model/reservationSummary';
import {Plugins} from '@capacitor/core';
import {NetworkOfflineError} from '@shared/network/network.interceptor';
import {NetworkService} from '@shared/network/network.service';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {switchMap, tap} from 'rxjs/operators';

export const LINKED_RESERVATIONS_KEY = 'LINKED_RESERVATIONS';
export const RESERVATION_DETAILS_KEY = 'RESERVATION_';

@Injectable({
  providedIn: 'root'
})
export class ReservationsCacheService {

  constructor(private guestReservationsService: GuestReservationsService,
              private networkService: NetworkService) {
  }

  public getLinkedReservations(): Observable<ReservationSummary[]> {
    return this.networkService.isOnline()
      .pipe(switchMap(online => online ? this.getApiLinkedReservations() : this.getCachedLinkedReservations()));
  }

  private getApiLinkedReservations(): Observable<ReservationSummary[]> {
    return this.guestReservationsService.getLinkedReservations()
      .pipe(tap(reservations => Plugins.Storage.set({
        key: LINKED_RESERVATIONS_KEY,
        value: JSON.stringify(reservations)
      })));
  }

  private getCachedLinkedReservations(): Observable<ReservationSummary[]> {
    const getLinkedReservationsPromise = Plugins.Storage.get({key: LINKED_RESERVATIONS_KEY})
      .then((token) => this.parse<ReservationSummary[]>(token.value));
    return fromPromise(getLinkedReservationsPromise);
  }

  public getReservationDetails(bookingRef: string): Observable<ReservationDetails> {
    return this.networkService.isOnline()
      .pipe(switchMap(online => online ? this.getApiReservationDetails(bookingRef) : this.getCachedReservationDetails(bookingRef)));
  }

  private getApiReservationDetails(bookingRef: string): Observable<ReservationDetails> {
    return this.guestReservationsService.getReservationDetails(bookingRef)
      .pipe(tap(reservation => Plugins.Storage.set({
        key: `${RESERVATION_DETAILS_KEY}${bookingRef}`,
        value: JSON.stringify(reservation)
      })));
  }

  private getCachedReservationDetails(bookingRef: string): Observable<ReservationDetails> {
    const getReservationDetailsPromise = Plugins.Storage.get({key: `${RESERVATION_DETAILS_KEY}${bookingRef}`})
      .then((token) => this.parse<ReservationDetails>(token.value));
    return fromPromise(getReservationDetailsPromise);
  }

  /**
   * Fetching from the cache will return empty if there is no cache entry.
   * This behaviour is different from {@link getCachedLinkedReservations}.
   *
   * @return the cached linked reservations if a cache entry exists, otherwise an empty array.
   */
  public fetchCachedLinkedReservations(): Observable<ReservationSummary[]> {
    const getLinkedReservationsPromise =
      Plugins.Storage.get({key: LINKED_RESERVATIONS_KEY}).then(token => token.value ? JSON.parse(token.value) : []);
    return fromPromise(getLinkedReservationsPromise);
  }

  parse<TYPE>(value: string): TYPE {
    if (value) {
      return JSON.parse(value) as TYPE;
    }

    // cache has no entry => we are offline so we cannot make the API call
    throw new NetworkOfflineError();
  }
}
