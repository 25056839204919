/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ZzzingServiceType = 'EXTRA_CLEAN' | 'EXTRA_TOWEL' | 'LINEN_CHANGE' | 'BABY_COT' | 'OTHER';

export const ZzzingServiceType = {
    ExtraClean: 'EXTRA_CLEAN' as ZzzingServiceType,
    ExtraTowel: 'EXTRA_TOWEL' as ZzzingServiceType,
    LinenChange: 'LINEN_CHANGE' as ZzzingServiceType,
    BabyCot: 'BABY_COT' as ZzzingServiceType,
    Other: 'OTHER' as ZzzingServiceType
};

