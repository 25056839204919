import {Component, Input} from '@angular/core';
import {INotification} from '@shared/header-notification.service';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-notification-popover',
  templateUrl: './notification-popover.component.html',
  styleUrls: ['./notification-popover.component.scss']
})
export class NotificationPopoverComponent {

  @Input() notifications: INotification[] = [];

  constructor(private popoverController: PopoverController) {
  }

  dismissPopover(): void {
    this.popoverController.dismiss();
  }
}
