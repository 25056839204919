/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BookingStatus = 'PENDING' | 'BOOKED' | 'PAID' | 'CANCELLED' | 'EXPIRED' | 'REQUIRES_PAYMENT_CANCELLATION' | 'REQUIRES_PAYMENT_REFUND';

export const BookingStatus = {
    Pending: 'PENDING' as BookingStatus,
    Booked: 'BOOKED' as BookingStatus,
    Paid: 'PAID' as BookingStatus,
    Cancelled: 'CANCELLED' as BookingStatus,
    Expired: 'EXPIRED' as BookingStatus,
    RequiresPaymentCancellation: 'REQUIRES_PAYMENT_CANCELLATION' as BookingStatus,
    RequiresPaymentRefund: 'REQUIRES_PAYMENT_REFUND' as BookingStatus
};

