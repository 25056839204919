import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@shared/auth/auth.service';
import {PageUrls} from '@shared/page-urls';
import {Observable, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from '@env/environment';

export interface IRetryInfo {
  req: HttpRequest<any>;
  next: HttpHandler;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAccessToken().pipe(
      map(token => this.addBearerToken(req, token)),
      switchMap(request => next.handle(request)),
      catchError(error => this.handleError(error, {req, next}))); // on 1st attempt handle error with retry info
  }

  addBearerToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const apiBaseUrl = environment.apiUrl;
    if (token && req.url.startsWith(apiBaseUrl)) {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return req;
  }

  handleError(error: any, retryInfo?: IRetryInfo): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401:
          return retryInfo ? this.renewAuthAndRetry(retryInfo) : this.handleUnauthorized(error);
        default:
          break;
      }
    }

    return throwError(error);
  }

  /**
   * Cleans session state and redirects to login page
   *
   * @param error the unauthorized error
   */
  handleUnauthorized(error: any): Observable<HttpEvent<any>> {
    this.router.navigate([PageUrls.LOGIN]).then(() => this.authService.signOut().subscribe());
    return throwError(error);
  }

  /**
   * Attempts to renew the authentication and if successful it will retry the original request.
   *
   * @note ONLY the renew auth will be intercepted by this interceptor again (the retry request attempt is NOT).
   *       We assume that renew auth is RESTful and will NEVER throw a 401 (otherwise it may cause an infinite loop).
   *
   * @param retryInfo the info to be used to retry the request if the renew authentication succeeds.
   */
  renewAuthAndRetry(retryInfo: IRetryInfo): Observable<HttpEvent<any>> {
    // NOTE: the error thrown by the 1st catchError is still caught by the last catchError, but is only rethrown.
    return this.authService.renewAuth().pipe(
      catchError(error => this.handleUnauthorized(error)), // if renew auth fails we treat this as the original unauthorised!
      switchMap(() => this.authService.getAccessToken()),
      map(renewedAccessToken => this.addBearerToken(retryInfo.req, renewedAccessToken)),
      switchMap(request => retryInfo.next.handle(request)),
      catchError(error => this.handleError(error))); // on 2nd attempt we do NOT handle error with retry info
  }

}
