import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

import {PageUrls} from './page-urls';

@Injectable({
  providedIn: 'root',
})
export class BackButtonService {

  private goingBack = false;
  private previousUrls: string[] = [];

  private activeBackFunction: () => void;

  defaultBackFunction = (): void => {
    if (this.previousUrls.length > 0) {
      this.router.navigateByUrl(this.previousUrls.pop());
    } else {
      this.router.navigate([PageUrls.APP_ROOT], {replaceUrl: true});
    }
  };

  constructor(private router: Router) {
    this.activeBackFunction = this.defaultBackFunction;

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.resetBehaviour();
          if (this.shouldBeRecorded(event)) {
            this.previousUrls.push(this.router.url);
          }
          this.goingBack = false;
        }
      });
  }

  goBack(): void {
    this.goingBack = true;
    this.activeBackFunction();
  }

  clearHistory(): void {
    this.previousUrls = [];
  }

  overrideBackButtonClick(overrideFunction: () => void): void {
    this.activeBackFunction = overrideFunction;
  }

  resetBehaviour(): void {
    this.activeBackFunction = this.defaultBackFunction;
  }

  private shouldBeRecorded(event: NavigationStart): boolean {
    return !this.goingBack
      && event.navigationTrigger !== 'popstate'
      && this.router.url !== event.url
      && this.router.url.startsWith('/app');
  }

}
