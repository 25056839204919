import {Component, Input, ViewChild} from '@angular/core';
import {SwiperComponent} from 'ngx-swiper-wrapper';
import {SwiperOptions} from 'swiper';

@Component({
  selector: 'app-glide',
  templateUrl: './glide.component.html'
})
export class GlideComponent {
  @Input() settings: SwiperOptions;

  @ViewChild('swiper') swiper: SwiperComponent;

  constructor() {
  }

  nextSlide(): void {
    if (!this.swiper.directiveRef.swiper().isEnd) {
      this.swiper.directiveRef.nextSlide();
    } else {
      this.swiper.directiveRef.setIndex(0);
    }
  }

  prevSlide(): void {
    if (!this.swiper.directiveRef.swiper().isBeginning) {
      this.swiper.directiveRef.prevSlide();
    } else {
      this.swiper.directiveRef.swiper().slideTo(this.swiper.directiveRef.swiper().slides.length - 1);
    }
  }

}
