import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(private loadingController: LoadingController) {
  }

  openInAppBrowser(link: string): void {
    this.loadingController.create({message: 'Loading...'}).then((loadingElement) => {
      loadingElement.present().then(() => {
        Plugins.Browser.open({url: link}).then(() => loadingElement.dismiss()).catch(() => loadingElement.dismiss());
      });
    });
  }

  closeInAppBrowser(): void {
    Plugins.Browser.close();
  }
}
