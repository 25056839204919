<ion-content class="maxh-50vh">
  <div class="card card-body dropdown-menu w-100">
    <div *ngIf="notifications.length > 0" class="text-right mb-2" (click)="dismissPopover()">
      <i class="fa fa-times fa-2x color-theme-1"></i>
      <div class="border-bottom mt-2 mb-3"></div>
    </div>
    <div *ngFor="let notification of notifications">
      <div class="d-flex flex-row mb-3 pb-3 border-bottom">
        <img [src]="'/assets/img/notifications/'+ notification.img" alt="Notification Image"
             class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"/>
        <div class="pl-3">
          <p class="font-weight-medium mb-1 color-primary-1" [innerHTML]="notification.name"></p>
          <p class="text-muted mb-0 text-small">{{notification.time}}</p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="notifications.length === 0">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div>
          <h4 class="color-theme-1 m-0">No notifications</h4>
          <h5 class="color-theme-1 m-0">Have a good day!</h5>
        </div>
        <div class="text-right" (click)="dismissPopover()">
          <i class="fa fa-times fa-2x color-theme-1"></i>
        </div>
      </div>
    </ng-container>
  </div>
</ion-content>
