/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LinkReservationDetails { 
    /**
     * First name of reservation. If not already linked, this will be the first name received from the sales channel
     */
    first_name?: string;
    /**
     * Last name of reservation. If not already linked, this will be the first name received from the sales channel
     */
    last_name?: string;
    /**
     * Whether reservation has already been linked to a primary customer
     */
    is_linked?: boolean;
    /**
     * Location of reservation property
     */
    property_location?: string;
}

