import {Configuration} from '@api/configuration';
import {environment} from '@env/environment';

export const ApiConfigFactory = (): Configuration => {
  return new Configuration({
    basePath: environment.apiUrl
  });
};

export const MOMENT_API_DATE_FORMAT = 'YYYY-MM-DD';
