import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {environment} from '@env/environment';
import {Platform} from '@ionic/angular';
import {stringify} from 'flatted';

const {FirebaseCrashlytics} = Plugins;

@Injectable({
  providedIn: 'root',
})
export class LoggingService {

  constructor(private platform: Platform) {
  }

  public async setUserId(userId: string): Promise<void> {
    try {
      await this.doIfLoggableEnvironment(() => FirebaseCrashlytics.setUserId({userId}));
    } catch (e: any) {
      console.warn(`Crashlytics failed to set user id ${userId}: ${e}`);
    }
  }

  public async logError(e: any): Promise<void> {
    const message = stringify(e);
    try {
      await this.doIfLoggableEnvironment(() => FirebaseCrashlytics.recordException({message}));
    } catch (ex: any) {
      console.warn(`Crashlytics failed to record error ${message}: ${ex}`);
    }
  }

  private doIfLoggableEnvironment(func: () => Promise<any>): Promise<any> {
    if (environment.production && this.platform.is('hybrid')) {
      return func();
    }
  }

}
