import {CurrencyPipe, TitleCasePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {CancellationPolicyPipe} from '@app/pipes/cancellation-policy.pipe';
import {DisplayOptionalPipe} from '@app/pipes/display-optional.pipe';
import {EnumPipe} from '@app/pipes/enum.pipe';
import {PascalToTitlePipe} from '@app/pipes/pascalcase.pipe';

@NgModule({
  declarations: [EnumPipe, DisplayOptionalPipe, CancellationPolicyPipe, PascalToTitlePipe],
  imports: [],
  exports: [
    EnumPipe,
    DisplayOptionalPipe,
    CancellationPolicyPipe,
    PascalToTitlePipe
  ],
  providers: [
    EnumPipe,
    TitleCasePipe,
    DisplayOptionalPipe,
    CurrencyPipe,
    CancellationPolicyPipe
  ]
})
export class PipesModule {
}
