/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GuestDetailsUpdateParams { 
    name?: string;
    surname?: string;
    mobile_number?: string;
    /**
     * The full name of the country e.g. Japan
     */
    country?: string;
    allow_marketing?: boolean;
}

