<svg viewBox="0, 0, 400,164">
  <g id="Group">
    <path id="Path"
          d="M228.586 15.177 C 206.075 23.468,199.752 50.000,220.287 50.000 C 232.478 50.000,238.206 38.206,230.000 30.000
          C 224.041 24.041,230.844 19.455,245.645 19.455 C 274.647 19.455,281.156 57.850,253.049 63.123 L 246.403 64.370
          240.119 89.685 C 236.663 103.608,233.347 116.622,232.750 118.605 C 231.778 121.831,232.872 122.146,243.178 121.605
          L 254.691 121.000 258.862 103.823 C 263.066 86.511,270.008 74.277,278.081 69.956 C 283.188 67.223,283.173 70.102,
          277.969 90.753 C 271.410 116.784,273.697 124.000,288.504 124.000 C 296.199 124.000,308.000 116.024,308.000 110.822
          C 308.000 107.027,304.135 107.258,299.645 111.321 C 294.085 116.353,293.830 114.199,298.056 97.924 C 306.380
          65.869,295.979 50.882,274.500 63.978 C 266.870 68.630,266.455 67.788,272.318 59.553 C 291.202 33.033,261.618
          3.012,228.586 15.177 M130.000 25.954 C 130.000 29.716,131.265 30.000,148.000 30.000 C 157.900 30.000,166.000
          30.544,166.000 31.208 C 166.000 31.872,156.224 46.497,144.276 63.708 C 114.922 105.990,113.487 109.487,122.000
          118.000 C 128.165 124.165,186.000 124.165,186.000 118.000 C 186.000 114.254,184.667 114.000,165.000 114.000
          C 153.450 114.000,144.000 113.463,144.000 112.806 C 144.000 112.149,156.720 93.131,172.266 70.544 C 208.225
          18.299,208.904 23.122,165.500 22.454 C 131.018 21.923,130.000 22.024,130.000 25.954 M72.000 45.912 C 72.000
          49.369,73.639 49.893,86.053 50.412 L 100.107 51.000 82.264 76.525 C 53.680 117.414,54.667 122.000,92.046
          122.000 C 106.682 122.000,116.000 121.222,116.000 120.000 C 116.000 118.900,116.010 117.325,116.023 116.500
          C 116.035 115.675,108.880 114.550,100.123 114.000 L 84.200 113.000 107.031 80.000 C 135.689 38.575,136.233
          42.000,101.000 42.000 C 73.550 42.000,72.000 42.209,72.000 45.912 M333.661 63.955 C 312.740 75.287,305.860
          112.827,323.020 122.011 C 329.083 125.256,343.026 123.501,348.170 118.846 C 351.925 115.448,351.994 115.538,
          350.729 122.177 C 346.444 144.662,330.919 152.418,320.876 137.091 C 316.847 130.942,312.000 130.430,312.000
          136.154 C 312.000 145.659,322.933 152.534,336.659 151.660 C 362.496 150.015,368.597 139.280,385.058 66.500 C
          386.019 62.254,385.453 62.000,375.038 62.000 C 366.560 62.000,364.000 62.696,364.000 65.000 C 364.000 68.825,
          362.199 68.800,357.906 64.915 C 352.369 59.904,341.904 59.490,333.661 63.955 M24.000 66.000 C 24.000 69.407,
          25.333 70.000,33.000 70.000 C 44.163 70.000,44.462 68.446,29.000 90.778 C 9.679 118.683,10.890 122.000,40.400
          122.000 C 58.514 122.000,60.000 121.704,60.000 118.091 C 60.000 114.687,58.366 114.106,47.335 113.591 L 34.670
          113.000 51.208 89.103 C 60.303 75.959,67.299 64.484,66.755 63.603 C 64.251 59.552,24.000 61.809,24.000 66.000
          M203.492 64.500 C 197.726 79.898,193.440 113.847,196.644 118.737 C 202.300 127.369,218.146 125.524,226.456
          115.266 C 232.603 107.676,227.158 104.522,219.645 111.321 C 213.813 116.599,213.858 114.263,220.062 89.939
          C 227.662 60.141,227.985 62.000,215.214 62.000 C 208.418 62.000,204.082 62.925,203.492 64.500 M355.190
          73.009 C 362.794 84.615,355.145 108.019,341.587 114.626 C 332.897 118.861,332.273 115.646,337.677 94.492
          C 344.798 66.613,348.236 62.396,355.190 73.009 "
          stroke="white" fill="none">
    </path>
  </g>
</svg>
