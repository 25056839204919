import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';

import '@capacitor-community/firebase-analytics';

import {Plugins} from '@capacitor/core';
import {Platform} from '@ionic/angular';
import 'capacitor-plugin-app-tracking-transparency';
import {AppTrackingTransparencyPlugin} from 'capacitor-plugin-app-tracking-transparency';
import 'firebase/analytics';
import * as firebase from 'firebase/app';
import {stringify} from 'flatted';
import {filter} from 'rxjs/operators';
import EventName = firebase.analytics.EventName;

const AppTrackingTransparency = Plugins.AppTrackingTransparency as AppTrackingTransparencyPlugin;

const {FirebaseAnalytics} = Plugins;

export enum FirebaseZzzingEvents {
  LAUNCH_NAVIGATOR = 'launch_navigator',
  PUSH_NOTIFICATION_OPENED = 'push_notification_opened',
  LOGOUT = 'logout'
}

@Injectable({providedIn: 'root'})
export class AnalyticsService {

  constructor(private router: Router,
              private platform: Platform) {
  }

  public initialise(): void {
    this.requestTrackingPermission().then(() => this.trackScreenNavigations());
  }

  public trackScreenNavigations(): void {
    this.doIfAuthorised(() => this.router.events
      .pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        FirebaseAnalytics.setScreenName({screenName: e.url});
      }));
  }

  public setUser(userId: string): void {
    this.doIfAuthorised(() => FirebaseAnalytics.setUserId({userId}));
  }

  public setUserProperty(name: string, value: string): void {
    this.doIfAuthorised(() => FirebaseAnalytics.setUserProperty({name, value}));
  }

  public logEvent(eventName: string, eventParams: any): void {
    this.doIfAuthorised(() => FirebaseAnalytics.logEvent({name: eventName, params: eventParams}));
  }

  public logPurchaseEvent(amount: number): void {
    this.logEvent(EventName.PURCHASE, {value: +amount, currency: 'EUR'});
  }

  public logCouponEvent(couponName: string): void {
    this.logEvent(EventName.VIEW_PROMOTION, {name: couponName});
  }

  private doIfAuthorised(func: () => void): void {
    this.isTrackingAuthorised().then(authorised => {
      if (authorised) {
        this.doIfLoggableEnvironment(func);
      }
    });
  }

  private doIfLoggableEnvironment(func: () => void): void {
    if (this.platform.is('hybrid')) {
      try {
        func();
      } catch (e: any) {
        console.error(`Unable to process Firebase Analytics command: ${stringify(e)}`);
      }
    }
  }

  private requestTrackingPermission(): Promise<any> {
    if (this.platform.is('ios')) {
      return AppTrackingTransparency.requestPermission();
    } else {
      return Promise.resolve(true);
    }
  }

  private isTrackingAuthorised(): Promise<boolean> {
    if (this.platform.is('ios')) {
      return AppTrackingTransparency.getStatus().then(response => {
        console.log('Transparency status', response.status);
        return response.status === 'authorized';
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
