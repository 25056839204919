/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GuestRegisterLinkReservationParams { 
    /**
     * The booking reference of the reservation to be linked to the registered guest
     */
    booking_ref: string;
    /**
     * The check-in date of the reservation to be linked to the registered guest
     */
    check_in_date: string;
    /**
     * Whether the user should be registered and linked as the primary customer
     */
    is_primary: boolean;
}

