/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Zzzing service booking params
 */
export interface ZzzingServiceBookingParams { 
    reservation_booking_ref: string;
    date: string;
    time: string;
    allow_entry: boolean;
    number_of_units: number;
    phone_number?: string;
    additional_comments?: string;
}

