export enum PageUrls {

  // UNPROT
  USER_ROOT = 'user',
  LOGIN = 'user/login',
  SOCIAL_LOGIN = 'user/social',
  REGISTER = 'user/register',
  FORGOT_PASSWORD = 'user/forgot-password',

  APP_ROOT = 'app/tabs',

  // TABS
  MY_RESERVATIONS = 'app/tabs/my-reservations',
  VIRTUAL_RECEPTION = 'app/tabs/virtual-reception',
  HOME = 'app/tabs/home',

  // SERVICES
  AIRPORT_TRANSFERS = 'app/services/airport-transfer',
  TAXI = 'app/services/taxi',

  // TOURS AND SIGHTSEEING
  TOURS_AND_SIGHTSEEING = 'app/services/tours-sightseeing',
  TOURS = 'app/services/tours-sightseeing/tours',

  // RESTAURANTS
  RESTAURANTS = 'app/info/restaurants',

  // BEACHES
  BEACHES = 'app/info/beaches',

  // SPORT ACTIVITIES
  SPORT_ACTIVITIES = 'app/info/sport-activities',

  // ARTS, CULTURE & HISTORY ATTRACTIONS
  ARTS_CULTURE_AND_HISTORY_ATTRACTIONS = 'app/info/arts-culture-and-history-attractions',

  // KIDS ACTIVITIES
  KIDS_ACTIVITIES = 'app/info/kids-activities',

  // SHOPS
  SHOPS = 'app/info/shops',

  // TRANSPORT OPTIONS
  TRANSPORT_OPTIONS = 'app/info/transport-options',

  // ROOM SERVICES
  ROOM_SERVICES = 'app/services/room-services',
  ROOM_SERVICES_LIST = 'app/services/room-services/list',
  ROOM_SERVICES_DETAILS = 'app/services/room-services/details',

  // COUPONS
  COUPONS = 'app/info/coupons',

  // VIRTUAL RECEPTION
  AMEND_RESERVATION = 'app/virtual-reception-services/amend-reservation',
  FAQ = 'app/virtual-reception-services/faq',
  REPORT_ISSUE = 'app/virtual-reception-services/report-issue',

  // PROFILE
  PROFILE = 'app/profile',
  PERSONAL_INFORMATION = 'app/profile/personal-information',
  PAYMENT_INFORMATION = 'app/profile/payment-information',
  CHANGE_PASSWORD = 'app/profile/change-password',
  TERMS_AND_CONDITIONS = 'app/profile/terms-and-conditions',
  PRIVACY_POLICY = 'app/profile/privacy-policy',

  // OTHER PAGES
  ORDER_DETAILS = 'app/order-details/',
  ACTIVITY_DETAILS = 'app/activity-details/',
  COUPON_DETAILS = 'app/coupon-details',
  RESERVATION_DETAILS = 'app/reservation-details/',
  LINK_RESERVATION = 'app/link-reservation',
  COMPLETE_REGISTRATION = 'app/complete-registration',
  PREFERENCES = 'app/preferences'

}
