/**
 * Zzzing Guest API
 * Zzzing Guest API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The airport transfer location
 */
export type AirportTransferLocation = 'MALTA' | 'GOZO';

export const AirportTransferLocation = {
    Malta: 'MALTA' as AirportTransferLocation,
    Gozo: 'GOZO' as AirportTransferLocation
};

