import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule, SecurityContext} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {ApiModule} from '@api/api.module';
import {ApiConfigFactory} from '@app/api.config.factory';
import {AppComponent} from '@app/app.component';
import {AppErrorHandler} from '@app/app.errorhandler';
import {AppRoutingModule} from '@app/app.routing';
import {SplashComponent} from '@app/splash.component';
import {ViewsModule} from '@app/views/views.module';
import {environment} from '@env/environment';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Kommunicate} from '@ionic-native/kommunicate/ngx';
import {LaunchNavigator} from '@ionic-native/launch-navigator/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AuthInterceptor} from '@shared/auth/auth.interceptor';
import {NetworkInterceptor} from '@shared/network/network.interceptor';
import {UpgradeInterceptor} from '@shared/upgrade/upgrade.interceptor';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {NgxStripeModule} from 'ngx-stripe';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {ApolloGraphqlConfig} from '@app/apollographql.config.factory';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  spaceBetween: 20,
  freeMode: true,
  freeModeMomentumRatio: 0.5
};

const MARKED_DEFAULT_OPTIONS = {
  provide: MarkedOptions,
  useValue: {
    baseUrl: environment.strapi_base_url
  } as MarkedOptions
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ViewsModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    RatingModule.forRoot(),
    AccordionModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey, {locale: 'en'}),
    IonicModule.forRoot({animated: true, scrollPadding: false}),
    SwiperModule,
    ApiModule.forRoot(ApiConfigFactory),
    MarkdownModule.forRoot({
      markedOptions: MARKED_DEFAULT_OPTIONS,
      sanitize: SecurityContext.NONE
    })
  ],
  declarations: [
    AppComponent,
    SplashComponent
  ],
  providers: [
    StatusBar,
    ScreenOrientation,
    FileOpener,
    Kommunicate,
    LaunchNavigator,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    {provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UpgradeInterceptor, multi: true},
    {provide: ErrorHandler, useClass: AppErrorHandler},
    {provide: APOLLO_OPTIONS, useFactory: ApolloGraphqlConfig, deps: [HttpLink]}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
