import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {setThemeColor} from '@app/utils/util';
import {environment} from '@env/environment';

if (environment.production) {
  enableProdMode();
}

const color = environment.defaultColor;

const themeStyleSheetElement = setThemeColor(color);
const bootstrapApp = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

themeStyleSheetElement.onload = () => bootstrapApp();
