// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --dev` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  adminRoot: '/app',
  apiUrl: 'https://dev.zzzing.com/api/guest',
  appId: 'com.vh.zzzing',
  defaultColor: 'light.zzzing',
  stripePublishableKey: 'pk_test_51HHQWNBSwO7MXaAUNJ4M5yErOY6orJtR1LnYSWIk5HfCRAw0yzcGFdvSCLDkONcJwIe9W1mBnKx4gdKAFzXepMJs00xouZwQum',
  kommunicate: {
    appId: 'a64cc3ecb0c9ebc1e3ccf70c91460f52',
    apiUrl: 'https://services.kommunicate.io',
    apiKey: '4TyYCsIUUIGydKieyTdaoTZ3Am1TambU',
    deviceApnsType: 0
  },
  adminPanelUrl: 'https://admin.dev.zzzing.com/#/app',
  strapi_base_url: 'https://strapi.dev.zzzing.com'
};
